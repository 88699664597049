$ = jQuery;
function isMobile() {
    if ($(window).width() < 992) {
        return true;
    }
    return false;
}

$(function() {
	$(window).on("load", function () {
		var currLoc = $(location).attr("href");

		if ("currLoc:contains('locations')") {
			var service = currLoc
				.substring(currLoc.indexOf("=") + 1)
				.replaceAll("%20", " ");

			$(".tags-manager button").each(function () {
				if ($(this).text() == service) {
					$(this).trigger("click");
				}
			});
		}
	});

	$(".tags-manager button").click(function () {
		var tagsText = $(this).text();

		if (tagsText == "ALL") {
			window.history.pushState(
				null,
				null,
				window.location.href.substr(0, window.location.href.indexOf("?"))
			);
			$(".used-block, .sort-rep").css("display", "block");
		} else {
			window.history.pushState(null, null, "?category=" + tagsText);

			$(".used-block, .sort-rep").each(function () {
				if ($(this).data("sort-tags").indexOf(tagsText) == -1) {
					$(this).css("display", "none");
				} else {
					$(this).css("display", "block");
				}
			});
		}

		$("button.active").removeClass("active");
		$(this).addClass("active");
	});

	var scrollTop = 0;
	$(document).ready(function () {
		$(".home").addClass("anchored");
		scrollTop = $(window).scrollTop();

		if (scrollTop > 120) {
			$(".home").addClass("anchored");
		} else {
			$(".home").removeClass("anchored");
		}
	});

	$(window).scroll(function () {
		if ($(window).scrollTop() > 120) {
			$(".home").addClass("anchored");
		} else {
			$(".home").removeClass("anchored");
		}
	});

	/* grey navbar(.grey-nav) bounce control */
	// $(window).scroll(function () {
	// 	if (
	// 		$(window).scrollTop() + $(window).height() >
	// 		$(document).height() - 141
	// 	) {
	// 		if ($(window).width() > 768) {
	// 			$(".grey-nav-floating").css("bottom", "51px");
	// 		} else {
	// 			$(".grey-nav-floating").css("bottom", "89px");
	// 		}
	// 	} else {
	// 		$(".grey-nav-floating").css("bottom", "0");
	// 	}
	// });
	let timeoutId;
function adjustGreyNav() {
	const footerSubscribe = document.querySelector(".footer-subscribe");
	const greyNav = document.querySelector(".grey-nav-floating");
	if (!footerSubscribe) {
		return;
	}
	const footerSubscribeRect = footerSubscribe.getBoundingClientRect();
	const greyNavRect = greyNav.getBoundingClientRect();
	// get the height of the greyNav
	const greyNavHeight = greyNav.offsetHeight;

	if (greyNavRect.top > footerSubscribeRect.bottom) {
		greyNav.style.bottom =
			greyNavRect.bottom - footerSubscribeRect.bottom - greyNavHeight + "px";
	} else {
		greyNav.style.bottom = "0";
	}
}


	function debounce(func, delay) {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(func, delay);
	}

	window.addEventListener("resize", function () {
		debounce(adjustGreyNav, 500);
	});

	window.addEventListener("load", function () {
		adjustGreyNav();
	});

	window.addEventListener("scroll", function () {
		debounce(adjustGreyNav, 500);
	});

	//	$('#menu-main-menu > li > ul').append('<li class="adam"><img src="/wp-content/uploads/2020/07/nav-promo-mock.png"></li>');

	$(".accordion-header").click(function () {
		//        let slug = $(this).text().toLowerCase().replace(' ', '-');
		if ($(this).parent().hasClass("active")) {
			$(this).parent().removeClass("active");
			$(this).parent().find(".accordion-text").height(0);
		} else {
			$(".accordion-block.active .accordion-text").height(0);
			$(".accordion-block.active").removeClass("active");

			$(this).parent().addClass("active");

			let totalHeight = 0;
			$(".accordion-block.active .accordion-text")
				.children()
				.each(function () {
					totalHeight = totalHeight + $(this).outerHeight(true);
				});
			$(".accordion-block.active .accordion-text").height(totalHeight);
		}
	});

	$(".equipment-gallery img").click(function () {
		//		$('.javascript-image-target').css("background-image", "url(" + $(this).attr('src') + ")");
		$(".javascript-image-target img").attr("src", $(this).attr("src"));

		if ($(this).height() > 150) {
			$(".javascript-image-target").css("background-size", "auto 100%");
		} else {
			$(".javascript-image-target").css("background-size", "100% auto");
		}
	});

	//    $('#menu-main-menu > li ').click(function(e) {
	//        e.preventDefault();
	//		console.log('menu');
	//        if (isMobile()) {
	//            if ($(this).hasClass('clicked-once')) {
	//                window.location = $(this).attr('href');
	//            }
	//
	//            $('.clicked-once').removeClass('clicked-once');
	//            $(this).addClass('clicked-once');
	//            $(this).parent().addClass('clicked-once');
	//        } else {
	//            window.location = $(this).attr('href');
	//        }
	//
	//    })

	$("#equipment-search button").click(function () {
		window.location =
			"/search?search-term=" + $("#equipment-search input").val();
	});

	$("#equipment-search input").keyup(function (key) {
		$("#search-results").remove();
		if ($(this).val().length > 2) {
			$("#equipment-search").append('<div id="search-results"></div>');

			$.get(
				"/wp-json/catplugin/v1/search?data=" + $(this).val(),
				function (data) {
					$("#search-results").empty();

					for (let i = 0; i < data.length; i++) {
						//    					console.log(data[i]);
						var output = "";

						if (!data[i].status) {
							// These are the new equipments
							output +=
								'<a href="/machines/new/' +
								data[i].slug_name +
								"/" +
								data[i].slug +
								'">';
							output += data[i].product_name;
							output += "</a>";
						} else {
							// let parentSlug = '';

							// console.log(data[i]);
							output +=
								'<a href="/machines/used/' +
								data[i].slug_name +
								"/" +
								data[i].model +
								"/" +
								data[i].serial +
								'">';
							output += data[i].manufacturer;
							output += data[i].model;
							output += "</a>";
						}

						$("#search-results").append(output);
					}
				}
			);
		}
	});

	$("#filter-hide-target").click(function () {
		$(".categories-filter").toggleClass("hide-tags");
	});

	$(".chat-link").click(function (e) {
		e.preventDefault();

		$(".olark-launch-button").click();
	});

	$(".home .action-icon").click(function () {
		$(this)
			.find(".fa.fa-commenting")
			.each(function () {
				$(".olark-launch-button").click();
			});
	});

	$(".search-block .search-submit").click(function () {
		window.location =
			"/search?search-term=" + $(".search-block .searchbox").val();
	});

	$(".search-block .searchbox").on("keyup", function (key) {
		if (key.keyCode === 13) {
			$(".search-block .search-submit").click();
		}
	});

	$(".us-button").click(function () {
		$(this).addClass("active");
		$(".metric-button").removeClass("active");
		$(".equipment-specs").removeClass("measure-met").addClass("measure-us");
	});

	$(".metric-button").click(function () {
		$(this).addClass("active");
		$(".us-button").removeClass("active");
		$(".equipment-specs").addClass("measure-met").removeClass("measure-us");
	});

	$(".selector").click(function () {
		$(".selector.active").removeClass("active");
		$(this).addClass("active");

		if ($(this).data("etab") == "specs") {
			$(".equipment-specs.specs").removeClass("tab-hidden");
			$(".equipment-specs.equips").addClass("tab-hidden");
			$(".product-brochure").addClass("tab-hidden");
			$(".drawings").addClass("tab-hidden");
			$(".cat-attachments").addClass("tab-hidden");
		}

		if ($(this).data("etab") == "equips") {
			$(".equipment-specs.equips").removeClass("tab-hidden");
			$(".equipment-specs.specs").addClass("tab-hidden");
			$(".product-brochure").addClass("tab-hidden");
			$(".drawings").addClass("tab-hidden");
			$(".cat-attachments").addClass("tab-hidden");
		}

		if ($(this).data("etab") == "brochs") {
			$(".product-brochure").removeClass("tab-hidden");
			$(".equipment-specs.equips").addClass("tab-hidden");
			$(".equipment-specs.specs").addClass("tab-hidden");
			$(".drawings").addClass("tab-hidden");
			$(".cat-attachments").addClass("tab-hidden");
		}
		if ($(this).data("etab") == "drawings") {
			$(".drawings").removeClass("tab-hidden");
			$(".equipment-specs.equips").addClass("tab-hidden");
			$(".equipment-specs.specs").addClass("tab-hidden");
			$(".product-brochure").addClass("tab-hidden");
			$(".cat-attachments").addClass("tab-hidden");
		}
		if ($(this).data("etab") == "attachments") {
			$(".cat-attachments").removeClass("tab-hidden");
			$(".equipment-specs.equips").addClass("tab-hidden");
			$(".equipment-specs.specs").addClass("tab-hidden");
			$(".product-brochure").addClass("tab-hidden");
			$(".drawings").addClass("tab-hidden");
		}
	});

	$(".machineSearchFilter").click(function () {
		$(".machineSearchFilter.selected").removeClass("selected");
		$(this).addClass("selected");
	});

	$(".iMachineSearch input").click(function () {
		let newUsed = $(".machineSearchFilter.selected").data("filter-value");

		let category = $("#machineSearchCategoryList").val();

		var newUrl = "";

		if (newUsed == "rental") {
			newUrl += "/rentals";
		} else {
			newUrl += "/machines";

			if (newUsed == "new") {
				newUrl += "/new";
			} else {
				newUrl += "/used";
			}
		}

		newUrl += "/" + category;

		window.location = newUrl;
	});

	if (
		$(".post-type-rental_products .content-body.right > img:first-child")
			.length > 0 &&
		isMobile()
	) {
		$(
			".post-type-rental_products .content-body.right > img:first-child"
		).prependTo(".content-body.left");
	}

	if (isMobile()) {
		$(".iMachineSearch").each(function () {
			$(this).parent().after(this);
		});
	}

	$(document).ready(function () {
		if (
			$(".append-footer-form ").find('.nf-field-element input[type="hidden"]')
		) {
			if ($('.nf-field-element input[type="hidden"]').val()) {
				var src = $('.nf-field-element input[type="hidden"]').val();
				//				console.log(src);
				src = src.toString();
				//				console.log(src);
				$(".append-footer-form ").css("background-image", "url(" + src + ")");
			}
		}
	});

	//video controls
	$(document).ready(function () {
		$(".video-content-block").each(function () {
			var vidFrame = $(this).find("#video");
			var vidSrc = $(this).find("#video").attr("src");
			var vidSrcPlay = vidSrc + "?&autoplay=1";
			var modal = $(this).find(".modal");

			$(this)
				.find(".video-control")
				.click(function () {
					vidFrame.attr("src", vidSrcPlay);
					modal.addClass("show-vid");
				});

			$(this)
				.find("#contactModalCenter")
				.click(function () {
					vidFrame.attr("src", vidSrc);
					modal.removeClass("show-vid");
				});
		});
	});

	$(document).ready(function () {
		var height = $(".equipment-specs.specs.equip").height();
		var calcHeight = height / 2 + 1500;
		var roundHeight = Math.round(calcHeight / 10) * 10;

		$(".equipment-specs.specs.equip").height(roundHeight);
	});

	$(document).ready(function () {
		setTimeout(function () {
			//set product detail page make/modle page
			var product_text = $(".page-title").text();
			var used_serial = $(".serial").text();
			var newText = product_text.replace(/\s+/g, " ");

			if (used_serial) {
				$(".make-model").val(newText + "/" + used_serial);
			} else {
				$(".make-model").val(newText);
			}

			$(".make-model").prop("disabled", true);
		}, 100);
	});

	//BEGIN  loactions page cat filter hacks
	$(".wpsl-cat-filter-drop p").click(function () {
		$(".wpsl-cat-filter-drop").toggleClass("cat-active");
	});

	var cats_displayed = [];

	$(".wpsl-cat-filter-drop input").click(function () {
		var cat_value = $(this).attr("value");

		if (cats_displayed.length === 0) {
			$(".wpsl-cat-filter-display").append($(this).parent().clone());
			cats_displayed.push(cat_value);
		} else {
			if (jQuery.inArray(cat_value, cats_displayed) !== -1) {
				$(".wpsl-cat-filter-display label").each(function () {
					if (cat_value === $(this).children().attr("value")) {
						$(this).remove();
					}
				});

				cats_displayed = $.grep(cats_displayed, function (value) {
					return value != cat_value;
				});
			} else {
				$(".wpsl-cat-filter-display").append($(this).parent().clone());
				cats_displayed.push(cat_value);
			}
		}
	});

	$(document).on("click", ".wpsl-cat-filter-display label", function () {
		var display_val = $(this).children().attr("value");

		$(".wpsl-cat-filter-drop label").each(function () {
			if (display_val === $(this).children().attr("value")) {
				$(this).children().prop("checked", false);
			}
		});

		$(this).remove();

		cats_displayed = $.grep(cats_displayed, function (value) {
			return value != display_val;
		});

		$("#wpsl-search-btn").click();
	});

	$(document).mouseup(function (e) {
		var container = $(".wpsl-cat-filter-drop");

		if (!container.is(e.target) && container.has(e.target).length === 0) {
			container.removeClass("cat-active");
		}
	});
	//END  loactions page cat filter hacks

	$(".popup-button").click(function (e) {
		e.preventDefault();
		$(".popup-container .nf-form-cont").addClass("form-popped");
	});

	$(".nf-form-cont").click(function () {
		if (event.target !== this) {
			return;
		}
		$(this).removeClass("form-popped");
	});

	$(".popup-button-foot").click(function () {
		//		e.preventDefault();

		$(".popup-modal").css("display", "flex");
		$(".popup-container-foot .nf-form-cont").appendTo(".popup-modal");
	});

	$(".sticky-chat").click(function () {
		$(".olark-launch-button").click();
	});
}); 

//	$('.sticky-chat').click(function() {
//		//console.log('this');
//		$('.chat-client-bubble').click();
//	});
// })(jQuery);

window.closeRelease = function(e) {
	
	if( event.target == event.currentTarget ) {
		$('.popup-modal .nf-form-cont').appendTo('.popup-container-foot');
		$('.popup-modal').css('display', 'none');
	}
}

function sortByPrice(elem) {
    if ($(elem).hasClass('dsc')) {
        $('.certified-shortcode .equipment-tile').sort(sort_tiles).appendTo('.certified-shortcode');
        function sort_tiles(a, b) {
            return $(a).data('price') - $(b).data('price');
        }
        $(elem).addClass('asc').removeClass('dsc');
    } else if ($(elem).hasClass('asc')) {
//        console.log("DESC");
        $('.certified-shortcode .equipment-tile').sort(sort_tiles).appendTo('.certified-shortcode');
        function sort_tiles(a, b) {
            // return ($(b).data('price')) > ($(a).data('price')) ? -1 : 1;
            return $(b).data('price') - $(a).data('price');
        }
        $(elem).addClass('dsc').removeClass('asc');
    } else {
        $('.certified-shortcode .equipment-tile').sort(sort_tiles).appendTo('.certified-shortcode');
        function sort_tiles(a, b) {
            // return ($(b).data('price')) < ($(a).data('price')) ? 1 : -1;
            return $(a).data('price') - $(b).data('price');
        }
        $(elem).addClass('asc');
    }

    $('.sort-by-section .selected').removeClass('selected');
    $(elem).addClass('selected');
	
	$('.certified-shortcode').append($('.flex-buff'))
}

function sortByHours(elem) {
    // $('.certified-shortcode .equipment-tile').sort(sort_tiles).appendTo('.certified-shortcode');
    // function sort_tiles(a, b) {
    //     return ($(b).data('hours')) < ($(a).data('hours')) ? 1 : -1;
    // }

    // $('.sort-by-section .selected').removeClass('selected');
    // $(elem).addClass('selected');
    if ($(elem).hasClass('dsc')) {
        $('.certified-shortcode .equipment-tile').sort(sort_tiles).appendTo('.certified-shortcode');
        function sort_tiles(a, b) {
            return $(a).data('hours') - $(b).data('hours');
        }
        $(elem).addClass('asc').removeClass('dsc');
    } else if ($(elem).hasClass('asc')) {
//        console.log("DESC");
        $('.certified-shortcode .equipment-tile').sort(sort_tiles).appendTo('.certified-shortcode');
        function sort_tiles(a, b) {
            // return ($(b).data('price')) > ($(a).data('price')) ? -1 : 1;
            return $(b).data('hours') - $(a).data('hours');
        }
        $(elem).addClass('dsc').removeClass('asc');
    } else {
        $('.certified-shortcode .equipment-tile').sort(sort_tiles).appendTo('.certified-shortcode');
        function sort_tiles(a, b) {
            // return ($(b).data('price')) < ($(a).data('price')) ? 1 : -1;
            return $(a).data('hours') - $(b).data('hours');
        }
        $(elem).addClass('asc');
    }

    $('.sort-by-section .selected').removeClass('selected');
    $(elem).addClass('selected');
	
	$('.certified-shortcode').append($('.flex-buff'))
}

function sortByYear(elem) {
    // $('.certified-shortcode .equipment-tile').sort(sort_tiles).appendTo('.certified-shortcode');
    // function sort_tiles(a, b) {
    //     return ($(b).data('years')) < ($(a).data('years')) ? 1 : -1;
    // }

    // $('.sort-by-section .selected').removeClass('selected');
    // $(elem).addClass('selected');
    if ($(elem).hasClass('dsc')) {
        $('.certified-shortcode .equipment-tile').sort(sort_tiles).appendTo('.certified-shortcode');
        function sort_tiles(a, b) {
            return $(a).data('years') - $(b).data('years');
        }
        $(elem).addClass('asc').removeClass('dsc');
    } else if ($(elem).hasClass('asc')) {
//        console.log("DESC");
        $('.certified-shortcode .equipment-tile').sort(sort_tiles).appendTo('.certified-shortcode');
        function sort_tiles(a, b) {
            // return ($(b).data('price')) > ($(a).data('price')) ? -1 : 1;
            return $(b).data('years') - $(a).data('years');
        }
        $(elem).addClass('dsc').removeClass('asc');
    } else {
        $('.certified-shortcode .equipment-tile').sort(sort_tiles).appendTo('.certified-shortcode');
        function sort_tiles(a, b) {
            // return ($(b).data('price')) < ($(a).data('price')) ? 1 : -1;
            return $(a).data('years') - $(b).data('years');
        }
        $(elem).addClass('asc');
    }

    $('.sort-by-section .selected').removeClass('selected');
    $(elem).addClass('selected');
	
	$('.certified-shortcode').append($('.flex-buff'))
}

//Begin To Top Button
var topbutton = document.getElementById("toTopBtn");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    topbutton.style.visibility = "visible";
  } else {
    topbutton.style.visibility = "hidden";
  }
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
	document.getElementById('header-logo').focus();
}

$(document).ready(function() {
	$('#mobile-menu-hamburger').click(function() {
		$('.menu-main-menu-container').toggleClass('mobile-show');
		$('.mobile-show > ul > li').append('<p class="mobile-sub-open"></p>');
		
		$('.mobile-sub-open').on('click', function() {

			$('.mobile-sub-open').not(this).siblings('.sub-menu').hide();
			$('.mobile-sub-open').not(this).css('background-image', 'url("/wp-content/resources/assets/images/carter/icons/chevron-down.svg")');
			$(this).siblings('.sub-menu').toggle();

			if ($(this).siblings('.sub-menu').is(":hidden")) {
				$(this).css('background-image', 'url("/wp-content/resources/assets/images/carter/icons/chevron-down.svg")');
			} else {
				$(this).css('background-image', 'url("/wp-content/resources/assets/images/carter/icons/chevron-up.svg")');
			}
		});
    });

	$('.menu-toggle').on('click', function() {
		$('body').toggleClass('no-scroll');
		$('html').toggleClass('no-scroll');
	});
	
	$("#menu-floating-home-menu li").click(function() {
		var href = $(this).find('a').attr('href');
		if ( href == 'https://www.careersatcarter.com/' ) {
			window.open(href, '_blank');
		} else {
			window.open(href, '_self');
		}
	});
});




function isElementInViewport (el) {
    var rect = el[0].getBoundingClientRect();
    return (rect.top>-1 && rect.bottom <= $(window).height());
}

// var s= $('.footer-subscribe');

// $(window).on('load', function(){
    
//     if ((isElementInViewport(s))) {
// 		$(".grey-nav-floating").css({"bottom": "51px"});
// 	} 
    
// });

